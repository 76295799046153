module App

open Elmish
open Elmish.React

Fable.Core.JsInterop.importSideEffects "tailwindcss/tailwind.css"
Fable.Core.JsInterop.importSideEffects "./styles.css"

type Page =
    | Login of Login.State
    | Landing of Landing.State
    | Banner of Banner.State
    | Mailer of Mailer.State
    | Volunteer of Volunteer.State
    | Donate of Donate.State

type State = { CurrentPage: Page }

type Msg =
    | LoginMsg of Login.Msg
    | LandingMsg of Landing.Msg
    | BannerMsg of Banner.Msg
    | MailerMsg of Mailer.Msg
    | VolunteerMsg of Volunteer.Msg
    | DonateMsg of Donate.Msg

let init () =
    let loginState, loginCmd = Login.init ()

    let initialState = { CurrentPage = Login loginState }

    let initialCmd = Cmd.map LoginMsg loginCmd

    initialState, initialCmd

let update (msg: Msg) (state: State) : State * Cmd<Msg> =
    match msg, state.CurrentPage with
    | LoginMsg loginMsg, Login loginState ->
        match loginMsg with
        | Login.LoggedIn authenticated when authenticated ->
            let landingState = Landing.init ()

            { state with
                  CurrentPage = Landing landingState },
            Cmd.none
        | _ ->
            let updatedLogin, loginCmd = Login.update loginMsg loginState
            let appCmd = Cmd.map LoginMsg loginCmd

            { state with
                  CurrentPage = Login updatedLogin },
            appCmd

    | LandingMsg landingMsg, Landing _landingState ->
        match landingMsg with
        | Landing.PageChoice Landing.Banner ->
            let bannerState, bannerCmd = Banner.init ()
            { state with
                  CurrentPage = Banner bannerState },
            Cmd.map BannerMsg bannerCmd
        | Landing.PageChoice Landing.Mailer ->
            let mailerState, mailerCmd = Mailer.init ()
            { state with
                  CurrentPage = Mailer mailerState },
            Cmd.map MailerMsg mailerCmd
        | Landing.PageChoice Landing.Volunteer ->
            let volunteerState, volunteerCmd = Volunteer.init ()
            { state with
                  CurrentPage = Volunteer volunteerState },
            Cmd.map VolunteerMsg volunteerCmd
        | Landing.PageChoice Landing.Donate ->
            let donateState, donateCmd = Donate.init ()
            { state with
                  CurrentPage = Donate donateState },
            Cmd.map DonateMsg donateCmd
    // Additional page case handling goes here

    | BannerMsg bannerMsg, Banner bannerState ->
        match bannerMsg with
        | Banner.GoHome ->
            { state with
                  CurrentPage = Landing <| Landing.init () },
            Cmd.none
        | bannerMsg ->
            let updatedBanner, bannerCmd = Banner.update bannerMsg bannerState
            let appCmd = Cmd.map BannerMsg bannerCmd
            { state with
                  CurrentPage = Banner updatedBanner },
            appCmd

    | MailerMsg mailerMsg, Mailer mailerState ->
        match mailerMsg with
        | Mailer.GoHome ->
            { state with
                  CurrentPage = Landing <| Landing.init () },
            Cmd.none
        | mailerMsg ->
            let updatedMailer, mailerCmd = Mailer.update mailerMsg mailerState
            let appCmd = Cmd.map MailerMsg mailerCmd
            { state with
                  CurrentPage = Mailer updatedMailer },
            appCmd

    | VolunteerMsg volunteerMsg, Volunteer volunteerState ->
        match volunteerMsg with
        | Volunteer.GoHome -> { CurrentPage = Landing <| Landing.init () }, Cmd.none
        | msg ->
            let updatedVolunteer, volunteerCmd = Volunteer.update msg volunteerState
            let appCmd = Cmd.map VolunteerMsg volunteerCmd
            { CurrentPage = Volunteer updatedVolunteer }, appCmd

    | DonateMsg donateMsg, Donate donateState ->
        match donateMsg with
        | Donate.GoHome -> { CurrentPage = Landing <| Landing.init () }, Cmd.none
        | msg ->
            let updatedDonate, donateCmd = Donate.update msg donateState
            let appCmd = Cmd.map DonateMsg donateCmd
            { CurrentPage = Donate updatedDonate }, appCmd

    | _, _ -> state, Cmd.none


let render (state: State) (dispatch: Msg -> unit) =
    match (state.CurrentPage) with
    | Login loginState -> Login.render loginState (dispatch << LoginMsg)
    | Landing landingState -> Landing.render landingState (LandingMsg >> dispatch)
    | Banner bannerState -> Banner.render bannerState (BannerMsg >> dispatch)
    | Mailer mailerState -> Mailer.render mailerState (MailerMsg >> dispatch)
    | Volunteer volunteerState -> Volunteer.render volunteerState (VolunteerMsg >> dispatch)
    | Donate donateState -> Donate.render donateState (DonateMsg >> dispatch)

Program.mkProgram init update render
|> Program.withReactSynchronous "app"
|> Program.run
